import {
  CameraIcon,
  CheckBadgeIcon,
  ClipboardIcon,
  DocumentCheckIcon,
  MusicalNoteIcon,
  ReceiptRefundIcon,
  ShieldExclamationIcon,
  ShoppingCartIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';

export enum BonsaiQueueType {
  MustardVerification = 'mustard_verification',
  PostScoring = 'post_scoring_1_0',
  UserVerification = 'user_verification',
  LivPostScoring = 'liv_post_scoring_8_0',
  BrandProductCheck = 'brand_product_check',
  CommercialSoundCheck = 'commercial_sound_check',
  MapBrandRejection = 'map_brand_rejection',
  IshaPostEligibility = 'isha_post_eligibility',
  LivPostEligibility = 'liv_post_eligibility',
  RaffiPostScoring = 'raffi_post_scoring_1_0',
  ValidateContentModeration = 'validate_content_moderation_0_1',
  RequiredReviewContentModeration = 'required_review_content_moderation_0_1',
  ReceiptVerification = 'receipt_verification',
  ReviewGeminiCompletion = 'review_gemini_completion_0_1',
  VideoUploadPostScoring = 'video_upload_post_scoring_1_0',
  BrandRejectionReview = 'brand_rejection_review',
  ManualDownload = 'manual_download',
  ExperimentalPostScoring = 'experimental_post_scoring_0_1',
  ExperimentalPostScoringV3 = 'experimental_post_scoring_0_3',
  AccountReview = 'account_review',
  AccountReviewSebVerification = 'account_review_seb_verification',
  AccountReviewMikhaylaVerification = 'account_review_mikhayla_verification',
  AccountReviewCaliaVerification = 'account_review_calia_verification',
  PhotoUploadReview = 'photo_upload_review',
}

export enum BonsaiQueueGroupTypePrefix {
  PhotoUploadReviewByBrand = 'photo_upload_review_brand_',
}

export function toBonsaiQueue(value: string): BonsaiQueueType | undefined {
  if (Object.values(BonsaiQueueType).includes(value as BonsaiQueueType)) {
    return value as BonsaiQueueType;
  }

  if (value.startsWith(BonsaiQueueGroupTypePrefix.PhotoUploadReviewByBrand)) {
    return BonsaiQueueType.PhotoUploadReview;
  }

  return undefined;
}

export interface Admin {
  id: string;
  name: string;
}

export interface BonsaiQueue {
  type: BonsaiQueueType;
  name: string;
  friendlyName: string;
  taskDescription: string;
  icon?: any;
  remainingTasks?: number;
  oldestTaskCreationDate?: string;
  averageTaskAge?: string;
  activeAdmins?: Admin[];
}

export function getIconForBonsaiQueue(bonsaiQueueType: BonsaiQueueType) {
  switch (bonsaiQueueType) {
    case BonsaiQueueType.MustardVerification:
    case BonsaiQueueType.ValidateContentModeration:
    case BonsaiQueueType.RequiredReviewContentModeration:
      return ShieldExclamationIcon;
    case BonsaiQueueType.UserVerification:
      return CheckBadgeIcon;
    case BonsaiQueueType.PostScoring:
    case BonsaiQueueType.RaffiPostScoring:
    case BonsaiQueueType.VideoUploadPostScoring:
      return DocumentCheckIcon;
    case BonsaiQueueType.BrandProductCheck:
      return ShoppingCartIcon;
    case BonsaiQueueType.CommercialSoundCheck:
      return MusicalNoteIcon;
    case BonsaiQueueType.IshaPostEligibility:
    case BonsaiQueueType.LivPostEligibility:
      return VideoCameraIcon;
    case BonsaiQueueType.ReceiptVerification:
      return ReceiptRefundIcon;
    case BonsaiQueueType.PhotoUploadReview:
      return CameraIcon;
    default:
      return ClipboardIcon;
  }
}
