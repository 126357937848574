import { Deal } from '@kalecard/common';
import RefundDealComponent from '../RefundDealComponent';

interface PayDealFormInterface {
  deal: Deal;
  updateDeal: Function;
  showRefund?: boolean;
}

export default function PayDealForm({
  deal,
  updateDeal,
  showRefund = true,
}: PayDealFormInterface) {
  switch (deal.state) {
    case 'UNDONE':
      return (
        <>
          Refunded:{' '}
          <span className="font-bold">${deal.rewardAmount.toFixed(2)}</span>
        </>
      );
    case 'PAID':
      return (
        <PaidState
          deal={deal}
          updateDeal={updateDeal}
          showRefund={showRefund}
        />
      );
  }
}
function PaidState({
  deal,
  updateDeal,
  showRefund,
}: {
  deal: Deal;
  updateDeal: Function;
  showRefund: boolean;
}) {
  return (
    deal.rewardAmount != null && (
      <div>
        <p>
          Rewarded:{' '}
          <span className="font-bold">${deal.rewardAmount.toFixed(2)}</span>
        </p>
        {showRefund && (
          <RefundDealComponent
            deal={deal}
            updateDeal={updateDeal}
          />
        )}
      </div>
    )
  );
}
