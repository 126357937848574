import { Route, Switch } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../providers/UserProvider';
import ProfilePage from './ProfilePage';
import SignIn from './SignIn';
import CreatorPage from './CreatorPage';
import CreatorsPage from './CreatorsPage';
import Sidebar from '../components/navbar/Sidebar';
import KalePostsPage from './KalePostsPage';
import BrandsPage from './BrandsPage';
import BrandPage from './BrandPage';
import ChallengesPage from './ChallengesPage';
import ScoresPage from './ScoresPage';
import BonsaiPage from './BonsaiPage';
import WhitelistRequestsPage from './WhitelistRequestsPage';
import NotificationsPage from './NotificationsPage';
import { BonsaiQueueType } from '../components/bonsai/BonsaiModels';
import NonKaleBrandsPage from './NonKaleBrandsPage';
import BonsaiSharePage from './BonsaiSharePage';
import ChallengeTemplatesPage from './ChallengeTemplatesPage';
import UserManagementPage from './UserManagementPage';
import HealthCheck from './HealthCheck';
import ChallengeRequirementsPage from './ChallengeRequirementsPage';

export default function Application() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('authenticated') === 'true'
  );
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      setIsAuthenticated(localStorage.getItem('authenticated') === 'true');
    }
    return () => {};
  }, [user]);

  return (
    <>
      {isAuthenticated ? (
        <div>
          {user && (
            <div className="flex h-screen overflow-hidden bg-white">
              <Sidebar />
              <div className="flex w-0 flex-1 flex-col overflow-hidden">
                <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
                  <Switch>
                    <Route path="/profile">
                      <ProfilePage />
                    </Route>
                    <Route path="/creators/:id/">
                      <CreatorPage />
                    </Route>
                    <Route path="/creators">
                      <CreatorsPage />
                    </Route>
                    <Route path="/post-scoring">
                      <BonsaiPage defaultQueue={BonsaiQueueType.PostScoring} />
                    </Route>
                    <Route path="/posts">
                      <KalePostsPage />
                    </Route>
                    <Route path="/brands/:id/">
                      <BrandPage />
                    </Route>
                    <Route path="/brands">
                      <BrandsPage />
                    </Route>
                    <Route path="/healthCheck">
                      <HealthCheck />
                    </Route>
                    <Route path="/nonKaleBrands">
                      <NonKaleBrandsPage />
                    </Route>
                    <Route path="/scores">
                      <ScoresPage />
                    </Route>
                    <Route path="/challenges">
                      <ChallengesPage />
                    </Route>
                    <Route path="/whitelists">
                      <WhitelistRequestsPage />
                    </Route>
                    <Route path="/notifications">
                      <NotificationsPage />
                    </Route>
                    <Route path="/moderation">
                      <BonsaiPage
                        defaultQueue={BonsaiQueueType.MustardVerification}
                      />
                    </Route>
                    <Route path="/bonsai/:id">
                      <BonsaiSharePage />
                    </Route>
                    <Route path="/bonsai">
                      <BonsaiPage />
                    </Route>
                    <Route path="/templates">
                      <ChallengeTemplatesPage />
                    </Route>
                    <Route path="/requirements">
                      <ChallengeRequirementsPage />
                    </Route>
                    <Route path="/userManagement">
                      <UserManagementPage />
                    </Route>
                    <Route path="/">
                      <ChallengesPage />
                    </Route>
                  </Switch>
                </main>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Switch>
            <Route path="/">
              <SignIn />
            </Route>
          </Switch>
        </div>
      )}
    </>
  );
}
