import { useForm } from 'react-hook-form';
import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from '../../catalyst/fieldset';
import { Switch, SwitchField } from '../../catalyst/switch';
import { Input } from '../../catalyst/input';
import { Strong } from '../../catalyst/text';
import { useEffect, useRef, useState } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { Spinner } from '@kalecard/common';
import { Button } from '../../catalyst/button';
import { UPDATE_BRAND_INSTAGRAM_INFO } from '../../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { upload } from '../../../utils/upload';

interface BrandInstagramInfoInput {
  title?: string;
  subtitle?: string;
  minFollowerCount?: number;
  maxFollowerCount?: number;
  imageFiles?: File[];
}

export default function BrandInstagramInfo({ brand }) {
  const [isIgStoryReplyEnabled, setIsIgStoryReplyEnabled] = useState(
    brand.isIgStoryReplyEnabled == true
  );
  const [imageSrc, setImageSrc] = useState<string>(
    brand.instagramAutomatedMessageSettings?.imageUrl
  );
  const [uploadedImageBucketPath, setUploadedImageBucketPath] =
    useState<string>(undefined);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const [updateBrandInstagramInfo] = useMutation(UPDATE_BRAND_INSTAGRAM_INFO);

  const { register, handleSubmit, reset, watch } =
    useForm<BrandInstagramInfoInput>({
      defaultValues: {
        title: brand.instagramAutomatedMessageSettings?.title,
        subtitle: brand.instagramAutomatedMessageSettings?.subtitle,
        minFollowerCount:
          brand.instagramAutomatedMessageSettings?.minFollowerCount,
        maxFollowerCount:
          brand.instagramAutomatedMessageSettings?.maxFollowerCount,
      },
    });

  const onSubmit = async (data: BrandInstagramInfoInput) => {
    setLoading(true);

    const variables = {
      brandId: brand.id,
      isIgStoryReplyEnabled: isIgStoryReplyEnabled,
      maxFollowerCount: !data.maxFollowerCount
        ? null
        : Number(data.maxFollowerCount),
      minFollowerCount: !data.minFollowerCount
        ? null
        : Number(data.minFollowerCount),
      title: data.title,
      subtitle: data.subtitle,
      imageBucketPath: uploadedImageBucketPath,
    };

    try {
      await updateBrandInstagramInfo({
        variables: variables,
      });
      setSuccessMessage('Congrats, your changes have been applied!');
      clearSuccessMessage();
    } catch (err) {
      console.error(err);
      setErrorMessage('Failed to apply your changes, try again later.');
      clearErrorMessage();
    }
    setLoading(false);
  };

  useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      if (name === 'imageFiles') {
        if (
          FileReader && value.imageFiles.length > 0
            ? value.imageFiles[0].name
            : null
        ) {
          var fr = new FileReader();
          fr.onload = function () {
            setImageSrc(String(fr.result));
          };
          fr.readAsDataURL(value.imageFiles[0]);
        }

        if (value.imageFiles.length > 0) {
          const uploadImageResult = await upload(
            value.imageFiles[0],
            'BRAND_INSTAGRAM_AUTO_DM_IMAGE',
            {
              data: [{ key: 'brandId', value: brand.id }],
            }
          );

          setUploadedImageBucketPath(uploadImageResult?.bucketPath);
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <form
      className="space-y-4"
      key="instagram"
    >
      <Fieldset>
        <div className="flex justify-center">
          <Legend>Instagram Auto DM Settings</Legend>
        </div>
        <FieldGroup>
          <div className="flex flex-row space-x-20">
            <div className="flex w-full flex-col space-y-4">
              <SwitchField className="w-fit">
                <Label>
                  <Strong>Instagram Auto DM Enabled</Strong>
                </Label>
                <Switch
                  color="emerald"
                  name="isIgStoryReplyEnabled"
                  checked={isIgStoryReplyEnabled}
                  onChange={setIsIgStoryReplyEnabled}
                />
              </SwitchField>
              <Field>
                <Label>Title</Label>
                <Input
                  name="title"
                  {...register('title')}
                  type="text"
                />
              </Field>
              <Field>
                <Label>Subtitle</Label>
                <Input
                  name="subtitle"
                  {...register('subtitle')}
                  type="text"
                />
              </Field>
              <div className="flex space-x-10">
                <Field className="w-full">
                  <Label>Minimum Followers</Label>
                  <Input
                    name="minFollowerCount"
                    {...register('minFollowerCount')}
                    type="number"
                    placeholder="Any"
                  />
                </Field>
                <Field className="w-full">
                  <Label>Maximum Followers</Label>
                  <Input
                    name="maxFollowerCount"
                    {...register('maxFollowerCount')}
                    type="number"
                    placeholder="Any"
                  />
                </Field>
              </div>
            </div>
            <div className="w-full">
              <Field>
                <Label>Asset</Label>
                <div className="flex items-center space-x-2">
                  {imageSrc ? (
                    <img
                      className="h-60 w-60 border border-kale-green-500 object-cover"
                      src={imageSrc}
                    />
                  ) : (
                    <PhotoIcon className="inline-block h-12 w-12" />
                  )}
                  <label className="ml-5 cursor-pointer rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="text-base leading-normal">Change</span>
                    <input
                      className="hidden"
                      {...register('imageFiles')}
                      accept="image/png,image/jpeg"
                      type="file"
                      name="imageFiles"
                    />
                  </label>
                </div>
              </Field>
            </div>
          </div>
        </FieldGroup>
      </Fieldset>
      <div className="flex flex-row items-center justify-end space-x-4">
        {successMessage && (
          <p className="text-sm font-medium text-green-500">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-sm font-medium text-red-500">{errorMessage}</p>
        )}
        {loading && (
          <div className="flex flex-wrap content-center justify-center pr-2">
            <Spinner size="h-6 w-6" />
          </div>
        )}
        <Button
          color="indigo"
          onClick={handleSubmit(onSubmit)}
        >
          Save Changes
        </Button>
      </div>
    </form>
  );
}
